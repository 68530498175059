import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from './pages/Login'
import PageProjetos from './pages/Projetos'
import PageSingleProjetos from './pages/Single_Projetos'
import PageContato from "./pages/Contato"
import UseMouseOrKeyboard from './modules/UseMouseOrKeyboard'
import DesignSystem from './DesignSystem/DesignSystem'
import { register } from 'swiper/element/bundle'
import logs from './utils/logs'
import ripple from './modules/ripple'
import formErros from './modules/formErros'
import animaAoScroll from './animation/animaAoScroll'
import animateSetup from './animation/animateSetup'
import Menu from './modules/menu'
import Contact from './modules/contact'

import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

Menu()

// Pages
PageHome()
PageLogin()?.init()
PageSingleProjetos()
PageProjetos()
PageContato()

// utils logs
logs()

//contacts
Contact()

Fancybox.bind('[data-fancybox]', {
	groupAll: true,
})

document.addEventListener('DOMContentLoaded', () => {
	document.body.classList.add('show')
})
